import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "marketplace"
    }}>{`Marketplace`}</h1>
    <hr></hr>
    <p>{`Marketplace is the place where you can buy characters from the other players if you don’t trust your own luck with the gacha boxes.`}</p>
    <p><img alt="market" src={require("./public/images/market.png")} /></p>
    <h3 {...{
      "id": "trading-activities"
    }}>{`Trading activities`}</h3>
    <ul>
      <li parentName="ul">{`Buy characters.`}</li>
      <li parentName="ul">{`Sell characters ( Listing from the dashboard ).`}</li>
    </ul>
    <p>{`Listing price cannot be cheaper the 350 CLC.`}</p>
    <h3 {...{
      "id": "marketplace-listing-duration"
    }}>{`Marketplace listing duration`}</h3>
    <p>{`Listed characters will show up in the marketplace for 7 days. After that, your characters will be unlisted automatically. You may re-list your characters if you want to continue selling. This mechanic helps us avoiding the congestion of data transfer on the marketplace server.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      